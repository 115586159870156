import { NgModule } from '@angular/core';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AuthServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.RegisterServiceProxy,
        ApiServiceProxies.RolServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.OrgUnitServiceProxy,
        ApiServiceProxies.UserConfigurationServiceProxy,
        ApiServiceProxies.ChangeLogServiceProxy,
        ApiServiceProxies.MailGroupServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.HelpServiceProxy,
        ApiServiceProxies.SettingClientServiceProxy,
        ApiServiceProxies.MobileServiceProxy,
        ApiServiceProxies.WorkShiftServiceProxy,
        ApiServiceProxies.ActivityTypeServiceProxy,
        ApiServiceProxies.ProviderServiceProxy,
        ApiServiceProxies.StoreMovementTypeServiceProxy,
        ApiServiceProxies.ZoneServiceProxy,
        ApiServiceProxies.AreaServiceProxy,
        ApiServiceProxies.DamageTypeServiceProxy,
        ApiServiceProxies.ReasonSuspensionServiceProxy,
        ApiServiceProxies.LocationTypeServiceProxy,
        ApiServiceProxies.LocationServiceProxy,
        ApiServiceProxies.MarketMaxAndMinServiceProxy,
        ApiServiceProxies.PartServiceProxy,
        ApiServiceProxies.PlatformServiceProxy,
        ApiServiceProxies.RouteServiceProxy,
        ApiServiceProxies.CommodityServiceProxy,
        ApiServiceProxies.UploadServiceProxy,
        ApiServiceProxies.DownloadScheduleServiceProxy,
        ApiServiceProxies.ReportServiceProxy,
        ApiServiceProxies.InventoryServiceProxy,
        ApiServiceProxies.DownloadServiceProxy,
        ApiServiceProxies.ReturnableServiceProxy,
        ApiServiceProxies.SupplyCardRouteServiceProxy,
        ApiServiceProxies.LockServiceProxy,
        ApiServiceProxies.ReasonRestartServiceProxy
    ]
})
export class ServiceProxyModule { }
