import { Component, Injector } from '@angular/core';
import { DateTimeService } from '../shared/services/datetime.service';
import { AppComponentBase } from './app-component-base';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent extends AppComponentBase {
    version = '';

    constructor(injector: Injector, public dateTimeService: DateTimeService) {
        super(injector);

        this.version = dateTimeService.getDateTimeToDisplayFromMilliseconds(
            Number.parseInt(this.browserStorageService.get('version'), 10)
        );
    }
}
