import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { ScrollPanel } from 'primeng/scrollpanel';
import { AppConsts } from 'src/shared/AppConsts';
import { AppComponentBase } from './app-component-base';
import { AppComponent } from './app.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent extends AppComponentBase implements OnInit, AfterViewInit {

    @Input() reset: boolean;

    model: any[];

    @ViewChild('layoutMenuScroller', { static: false }) layoutMenuScrollerViewChild: ScrollPanel;

    constructor(injector: Injector, public app: AppComponent) { super(injector); }

    ngOnInit() {
        this.model = [
            {
                label: this.l('Dashboard'),
                icon: 'pi pi-chart-bar',
                routerLink: ['/app/main/dashboard'],
                permissionName: 'Pages.Tenant.Dashboard'
            },
            {
                label: this.l('Instances'),
                icon: 'fa fa-th',
                routerLink: ['/app/admin/tenant'],
                permissionName: 'Pages.Tenants',
                visible: AppConsts.isHost
            },
            {
                label: this.l('Administration'), icon: 'pi pi-briefcase',
                items: [
                    {
                        label: this.l('Roles'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/admin/roles'],
                        permissionName: 'Pages.Administration.Roles'
                    },
                    {
                        label: this.l('Languages'),
                        icon: 'pi pi-comment',
                        routerLink: ['/app/admin/languages'],
                        permissionName: 'Pages.Administration.Languages'
                    },
                    {
                        label: this.l('Users'),
                        icon: 'pi pi-users',
                        routerLink: ['/app/admin/users'],
                        permissionName: 'Pages.Administration.Users'
                    },
                    {
                        label: this.l('OrgUnits'),
                        icon: 'pi pi-sitemap',
                        routerLink: ['/app/admin/orgunits'],
                        permissionName: 'Pages.Administration.OrgUnits'
                    },
                    {
                        label: this.l('AuditLogs'),
                        icon: 'pi pi-lock',
                        routerLink: ['/app/admin/auditLogs'],
                        permissionName: 'Pages.Administration.AuditLogs'
                    },
                    {
                        label: this.l('Maintenance'),
                        icon: 'fa fa-fw fa-wrench',
                        routerLink: ['/app/admin/maintenance'],
                        permissionName: 'Pages.Administration.Host.Maintenance',
                        visible: !(AppConsts.multiTenancy && AppConsts.tenancyName)
                    },
                    {
                        label: this.l('Host.Settings'),
                        icon: 'pi pi-cog',
                        routerLink: ['/app/admin/host/settings'],
                        permissionName: 'Pages.Administration.Host.Settings',
                        visible: AppConsts.isHost
                    },
                    {
                        label: this.l('Tenant.Settings'),
                        icon: 'pi pi-cog',
                        routerLink: ['/app/admin/tenants/settings'],
                        permissionName: 'Pages.Administration.Tenant.Settings'
                    },
                    {
                        label: this.l('EmailGroups'),
                        icon: 'pi pi-envelope',
                        routerLink: ['/app/admin/emailgroups'],
                        permissionName: 'conftcor.0'
                    },
                    {
                        label: this.l('Helps'),
                        icon: 'pi pi-question',
                        routerLink: ['/app/admin/helps'],
                        permissionName: 'Pages.Administration.Helps'
                    }
                ]
            },
            {
                label: this.l('Catalogs'), icon: 'pi pi-briefcase',
                items: [
                    {
                        label: this.l('WorkShifts'),
                        icon: 'pi pi-clock',
                        routerLink: ['/app/catalog/workshifts'],
                        permissionName: 'Pages.Tenant.WorkShifts'
                    },
                    {
                        label: this.l('Zones'),
                        icon: 'pi pi-map',
                        routerLink: ['/app/catalog/zones'],
                        permissionName: 'Pages.Tenant.Zones'
                    },
                    {
                        label: this.l('Areas'),
                        icon: 'pi pi-map-marker',
                        routerLink: ['/app/catalog/areas'],
                        permissionName: 'Pages.Tenant.Areas'
                    },
                    {
                        label: this.l('LocationTypes'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/locationtypes'],
                        permissionName: 'Pages.Tenant.LocationTypes'
                    },
                    {
                        label: this.l('Locations'),
                        icon: 'pi pi-flag',
                        routerLink: ['/app/catalog/locations'],
                        permissionName: 'Pages.Tenant.Locations'
                    },
                    {
                        label: this.l('Platforms'),
                        icon: 'pi pi-table',
                        routerLink: ['/app/catalog/platforms'],
                        permissionName: 'Pages.Tenant.Platforms'
                    },
                    {
                        label: this.l('Parts'),
                        icon: 'pi pi-ellipsis-h',
                        routerLink: ['/app/catalog/parts'],
                        permissionName: 'Pages.Tenant.Parts'
                    },
                    {
                        label: this.l('Mobiles'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/mobiles'],
                        permissionName: 'Pages.Tenant.Mobiles'
                    },
                    {
                        label: this.l('ActivityTypes'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/activitytypes'],
                        permissionName: 'Pages.Tenant.ActivityTypes'
                    },
                    {
                        label: this.l('Providers'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/providers'],
                        permissionName: 'Pages.Tenant.Providers'
                    },
                    {
                        label: this.l('StoreMovementTypes'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/storemovementtypes'],
                        permissionName: 'Pages.Tenant.StoreMovementTypes'
                    },
                    {
                        label: this.l('DamageTypes'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/damagetypes'],
                        permissionName: 'Pages.Tenant.DamageTypes'
                    },
                    {
                        label: this.l('ReasonsSuspension'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/reasonssuspension'],
                        permissionName: 'Pages.Tenant.ReasonsSuspension'
                    },
                    {
                        label: this.l('MarketMaxAndMins'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/marketmaxandmins'],
                        permissionName: 'Pages.Tenant.MarketMaxAndMins'
                    },
                    {
                        label: this.l('Commodities'),
                        icon: 'pi pi-th-large',
                        routerLink: ['/app/catalog/commodities'],
                        permissionName: 'Pages.Tenant.Commodities'
                    },
                    {
                        label: this.l('Routes'),
                        icon: 'pi pi-directions',
                        routerLink: ['/app/catalog/routes'],
                        permissionName: 'Pages.Tenant.Routes'
                    },
                    {
                        label: this.l('Returnables'),
                        icon: 'pi pi-list',
                        routerLink: ['/app/catalog/returnables'],
                        permissionName: 'Pages.Tenant.Returnables'
                    }
                ]
            },
            {
                label: this.l('Uploads.Campus'),
                icon: 'pi pi-upload',
                permissionName: 'Pages.Tenant.Uploads.Campus',
                items: [
                    {
                        label: this.l('Uploads.Campus'),
                        icon: 'pi pi-upload',
                        routerLink: ['/app/uploads/campus'],
                        permissionName: 'Pages.Tenant.Uploads.Campus'
                    },
                    {
                        label: this.l('Uploads.LogErrors'),
                        icon: 'pi pi-times-circle',
                        routerLink: ['/app/uploads/campus/logerrors'],
                        permissionName: 'Pages.Tenant.Uploads.Campus'
                    },
                    {
                        label: this.l('Uploads.LogSuccess'),
                        icon: 'pi pi-check-circle',
                        routerLink: ['/app/uploads/campus/logsuccess'],
                        permissionName: 'Pages.Tenant.Uploads.Campus'
                    }
                ]
            },
            {
                label: this.l('Uploads.Inbound'),
                icon: 'pi pi-upload',
                permissionName: 'Pages.Tenant.Uploads.Inbound',
                items: [
                    {
                        label: this.l('Uploads.Inbound'),
                        icon: 'pi pi-upload',
                        routerLink: ['/app/uploads/inbound'],
                        permissionName: 'Pages.Tenant.Uploads.Inbound'
                    },
                    {
                        label: this.l('Uploads.LogErrors'),
                        icon: 'pi pi-times-circle',
                        routerLink: ['/app/uploads/inbound/logerrors'],
                        permissionName: 'Pages.Tenant.Uploads.Inbound'
                    },
                    {
                        label: this.l('Uploads.LogSuccess'),
                        icon: 'pi pi-check-circle',
                        routerLink: ['/app/uploads/inbound/logsuccess'],
                        permissionName: 'Pages.Tenant.Uploads.Inbound'
                    }
                ]
            },
            {
                label: this.l('DownloadSchedules.Downloads'),
                icon: 'pi pi-download',
                permissionName: 'Pages.Tenant.DownloadSchedules',
                items: [
                    {
                        label: this.l('DownloadSchedules.Schedule'),
                        icon: 'pi pi-calendar',
                        routerLink: ['/app/downloads/schedules'],
                        permissionName: 'Pages.Tenant.DownloadSchedules'
                    },
                    {
                        label: this.l('DownloadDepuration.Depurate'),
                        icon: 'pi pi-check-square',
                        routerLink: ['/app/downloads/depuration'],
                        permissionName: 'Pages.Tenant.DownloadDepuration'
                    }
                ]
            },
            {
                label: this.l('Reports'),
                icon: 'pi pi-file',
                permissionName: 'Pages.Tenant.Reports',
                items: [
                    {
                        label: this.l('Reports.Stocked'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/stocked'],
                        permissionName: 'Pages.Tenant.Reports.Stocked'
                    },
                    {
                        label: this.l('Reports.Download'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/download'],
                        permissionName: 'Pages.Tenant.Reports.Download'
                    },
                    {
                        label: this.l('Reports.Putaway'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/putaway'],
                        permissionName: 'Pages.Tenant.Reports.Putaway'
                    },
                    {
                        label: this.l('Reports.JIT'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/jit'],
                        permissionName: 'Pages.Tenant.Reports.JIT'
                    },
                    {
                        label: this.l('Reports.Empty'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/empty'],
                        permissionName: 'Pages.Tenant.Reports.Empty'
                    },
                    {
                        label: this.l('Reports.LocationStock'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/locationstock'],
                        permissionName: 'Pages.Tenant.Reports.LocationStock'
                    },
                    {
                        label: this.l('Reports.WarehouseMovement'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/warehousemovement'],
                        permissionName: 'Pages.Tenant.Reports.WarehouseMovement'
                    },
                    {
                        label: this.l('Reports.TimeInStock'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/timeinstock'],
                        permissionName: 'Pages.Tenant.Reports.TimeInStock'
                    },
                    {
                        label: this.l('Reports.MaxMin'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/maxmin'],
                        permissionName: 'Pages.Tenant.Reports.MaxMin'
                    },
                    {
                        label: this.l('Reports.Activity'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/activity'],
                        permissionName: 'Pages.Tenant.Reports.Activity'
                    },
                    {
                        label: this.l('Reports.SupplyCardDelivery'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/supplycarddelivery'],
                        permissionName: 'Pages.Tenant.Reports.SupplyCardDelivery'
                    },
                    {
                        label: this.l('Reports.ReasonRestartLog'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/reports/reasonrestartlog'],
                        permissionName: 'Pages.Tenant.Reports.ReasonRestartLog'
                    }
                ]
            },
            {
                label: this.l('SupplyCards'),
                icon: 'pi pi-credit-card',
                permissionName: 'Pages.Tenant.SupplyCards',
                items: [
                    {
                        label: this.l('SupplyCards.Settings'),
                        icon: 'pi pi-cog',
                        routerLink: ['/app/supplycards/configuration'],
                        permissionName: 'Pages.Tenant.SupplyCards.Settings'
                    },
                    {
                        label: this.l('SupplyCards.Routes'),
                        icon: 'pi pi-directions',
                        routerLink: ['/app/supplycards/routes'],
                        permissionName: 'Pages.Tenant.SupplyCardRoutes'
                    }
                ]
            },
            {
                label: this.l('Locks'),
                icon: 'pi pi-cog',
                permissionName: 'Pages.Tenant.Locks',
                items: [
                    {
                        label: this.l('Locks'),
                        icon: 'pi pi-cog',
                        routerLink: ['/app/locks/locks'],
                        permissionName: 'Pages.Tenant.Locks'
                    },
                    {
                        label: this.l('LockLogs'),
                        icon: 'pi pi-file-excel',
                        routerLink: ['/app/locks/logs'],
                        permissionName: 'Pages.Tenant.LockLogs'
                    }
                ]
            }
        ];
    }

    ngAfterViewInit() {
        setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    onMenuClick(event) {
        if (!this.app.isHorizontal()) {
            setTimeout(() => {
                this.layoutMenuScrollerViewChild.moveBar();
            }, 450);
        }
        this.app.onMenuClick(event);
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li *ngIf="showMenuItem(child)" [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                   *ngIf="!child.routerLink" [ngClass]="child.styleClass"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
                    <span class="layout-menuitem-text">{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
                   [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [fragment]="child.fragment"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon" class="layout-menuitem-icon"></i>
                    <span class="layout-menuitem-text">{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">{{child.label}}</div>
                </div>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent extends AppComponentBase {
    @Input() item: MenuItem;
    @Input() root: boolean;
    @Input() visible: boolean;
    _parentActive: boolean;
    _reset: boolean;
    activeIndex: number;
    constructor(injector: Injector, public app: AppComponent, public appMenu: AppMenuComponent, public router: Router) { super(injector); }
    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;
        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.appMenu.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }
            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        if (item['reloadAlways']) {
            this.app.redirectTo(item.routerLink);
        }
    }
    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }
    isActive(index: number): boolean {
        return this.activeIndex === index;
    }
    @Input() get reset(): boolean {
        return this._reset;
    }
    set reset(val: boolean) {
        this._reset = val;
        if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
            this.activeIndex = null;
        }
    }
    @Input() get parentActive(): boolean {
        return this._parentActive;
    }
    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }

    showMenuItem(menuItem): boolean {

        if (menuItem.visible === false) {
            return false;
        }

        if (menuItem.permissionName) {
            return this.permission.isGranted(menuItem.permissionName);
        }

        if (menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return true;
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            const subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this.permission.isGranted(subMenuItem.permissionName) && subMenuItem.visible !== false) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }
}
