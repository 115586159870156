import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { TextNumberComponent } from './components/algoria/app.textnumber.component';
import { AppAlertComponent } from './components/app.alert.component';
import { BlockableDivComponent } from './components/app.blockable.component';
import { AppDataGridViewComponent } from './components/app.datagridview.component';
import { AppEditorComponent } from './components/app.editor.component';
import { AppHelpOnScreenComponent } from './components/app.help.onscreen.component';
import { PasswordFieldComponent } from './components/app.passwordfield.component';
import { AppPreloaderComponent } from './components/app.preloader.component';
import { SelectUsersComponent } from './components/selectusers.component';
import { SharedRoutingModule } from './shared-routing.module';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,

        DialogModule,
        ButtonModule,
        SidebarModule,
        CheckboxModule,
        BlockUIModule,
        TableModule,
        InputTextModule,
        VirtualScrollerModule,
        MenubarModule,
        InputTextareaModule,
        FileUploadModule,
        PanelModule,
        OverlayPanelModule,
        InputMaskModule,
        LightboxModule,
        ScrollPanelModule,
        PasswordModule,
        DividerModule,
        SharedRoutingModule
    ],
    declarations: [
        AppAlertComponent,
        AppHelpOnScreenComponent,
        AppDataGridViewComponent,
        SelectUsersComponent,
        AppEditorComponent,
        TextNumberComponent,
        BlockableDivComponent,
        PasswordFieldComponent,
        AppPreloaderComponent
    ],
    entryComponents: [],
    exports: [
        AppAlertComponent,
        AppHelpOnScreenComponent,
        AppDataGridViewComponent,
        SelectUsersComponent,
        AppEditorComponent,
        TextNumberComponent,
        BlockableDivComponent,
        PasswordFieldComponent,
        AppPreloaderComponent
    ]
})
export class SharedModule { }

