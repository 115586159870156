import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DummyComponent } from './dummy.component';
import { AuthGuard } from './_guards/auth.guard';

export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot([{
        path: 'app',
        component: AppComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                children: [
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' }
                ]
            },
            { path: 'dummy', component: DummyComponent },
            {
                path: 'main',
                loadChildren: () => import('../app/main/main.module').then(m => m.MainModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'catalog',
                loadChildren: () => import('../app/catalog/catalog.module').then(m => m.CatalogModule)
            },
            {
                path: 'uploads',
                loadChildren: () => import('../app/uploads/upload.module').then(m => m.UploadModule)
            },
            {
                path: 'downloads',
                loadChildren: () => import('../app/downloads/download.module').then(m => m.DownloadModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('../app/reports/report.module').then(m => m.ReportModule)
            },
            {
                path: 'supplycards',
                loadChildren: () => import('../app/supplycards/supplycards.module').then(m => m.SupplyCardModule)
            },
            {
                path: 'locks',
                loadChildren: () => import('../app/locks/locks.module').then(m => m.LocksModule)
            }
        ]
    }], { relativeLinkResolution: 'legacy' });
