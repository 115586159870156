import { Component, Injector } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppComponentBase } from 'src/app/app-component-base';
import { AppConsts } from '../shared/AppConsts';
import { VersionCheckService } from '../shared/services/version.check.service';
import { AppComponent } from './app.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [MessageService]
})
export class AppTopBarComponent extends AppComponentBase {

    multiTenancy: boolean = AppConsts.multiTenancy;
    versionChanged: boolean;

    constructor(public app: AppComponent,
        injector: Injector,
        private versionCheckService: VersionCheckService,
        private confirmationService: ConfirmationService) {
        super(injector);

        this.versionCheckService.versionChanged.subscribe(x => {
            this.versionChanged = x;

            if (x) {
                this.confirmationService.confirm({
                    header: this.l('NewVersion'),
                    icon: 'pi pi-info',
                    message: this.l('NewAppVersionMessage'),
                    acceptLabel: this.l('UpdateNow'),
                    rejectLabel: this.l('Cancel'),
                    accept: () => {
                        this.updateAppVersion();
                    }
                });
            }
        });

        this.updateImageProfile();
    }

    updateImageProfile(): void {
        this.urlPictureProfile = this.getBaseServiceUrl() + '/api/User/GetPictureProfile?id=' +
            this.app.currentUser.userId + '&v' + (new Date().getTime());
    }

    updateAppVersion(): void {
        this.versionCheckService.updateVersion();
    }
}
