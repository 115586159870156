import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-preloader',
    template: `
        <p-blockUI [blocked]="blocked">
            <div class="preloader">
                <div class="preloader-content">
                    <div class="preloader-square"></div>
                    <div class="preloader-square"></div>
                    <div class="preloader-square"></div>
                    <div class="preloader-square"></div>
                    <div class="preloader-square"></div>
                    <div class="preloader-square"></div>
                    <div class="preloader-square"></div>
                </div>
            </div>
        </p-blockUI>
    `
})
export class AppPreloaderComponent {

    @Input() blocked: boolean;
}
